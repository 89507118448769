.login-main{
    width:50vw;
    margin:5rem auto;
}
.login-main img{
    height:5rem;
    margin:0 auto 2rem;
}
.login-cont{
    width:60%;
    background:white;
    padding:2rem;
    /* border-radius:5px; */
    margin:auto;
    box-shadow:1px 0px 5px 3px rgba(0,0,0,0.1)
}
.login-cont div{
    display:flex;
    flex-direction: column;
    text-align: left;
    margin:5% 2%;
}
.login-cont div label{
    font-size:1.3rem
}
.login-cont div input{
    padding:3%;
    border:none;
    outline:none;
    border-radius:3px;
    box-shadow:1px 1px 3px 1px rgba(0,0,0,0.1)
}
.login-cont button{
    border:none;
    outline:none;
    padding:0.5rem 3rem;
    border-radius:3px;
    background:#3ad4af;
    color:white;
    align-items: center;
    font-size:larger;
    margin-top: 1rem;
}