.job-form-container {
    padding: 10px 20px;
    background-color: azure;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.my-form {
    width: 700px;
    max-width: 100%;
    background-color: rgb(244, 239, 239);
    border-radius: 5px;
    margin: 20px 0px;
    padding: 10px 20px;
}

.input-label-con {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 4px;
}

.form-label {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    padding: 0;
}

.form-input {
    height: 40px;
    border: 0.67px solid;
    outline: none;
    border-radius: 8px;
}

.form-text-area {
    height: 120px;
}