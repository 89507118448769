.main-btn-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    /* overflow-x: auto; */
    height: max-content;

}

.job-btn {
    cursor: pointer;
    border: none;
    outline: none;
    padding: 5px 15px;
    background-color: rgb(1, 183, 123);
    font-size: 20px;
    font-weight: 600;
    color: white;
    border-radius: 50px;
    box-shadow: 4px 4px 4px #000;
    transition: 0.35s;
    white-space: pre;
}

.active-job-btn {
    background-color: rgb(20, 20, 120);
    box-shadow: 5px 10px 10px #000;
    scale: 1.05;
    transition: 0.45s;
}

.job-main-heading {
    font-size: 28px;
    font-weight: 600;
    text-decoration: underline;
    text-underline-offset: 7px;
    color: rgb(7, 8, 7);

    margin-bottom: 15px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.avl-jobs-container {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    padding: 10px 10px;
}

.job-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 4px 10px;
}

.job-card-heading {
    font-size: 17px;
    margin: 0;
}

.detailed-heading {
    font-size: 23px;
    margin-top: 6px;
    color: #048e3b;
}

.job-description {
    font-size: 18px;
    font-weight: 400;
}

.location-job-type-con {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 7px;
    flex-wrap: wrap;
}

.location-job-type-con>div {
    display: flex;
    gap: 4px;
    align-items: center;
    color: #0a05b0;
    font-size: 20px;
    font-weight: 500;
}

.employment-type {
    color: rgb(194, 12, 155) !important;
}

.edit-delete-con {
    position: absolute;
    right: 20px;

}

.edit-delete-icon {
    font-size: 28px !important;
    outline: none;
    border: none;
    background: transparent;

}


[data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
}

[data-title]:after {
    content: attr(data-title);
    position: absolute;
    top: -20px;
    left: 0%;
    padding: 4px 4px 4px 8px;
    color: #222;
    font-size: 20px;
    font-weight: 500;
    white-space: nowrap;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -moz-box-shadow: 0px 0px 4px #222;
    -webkit-box-shadow: 0px 0px 4px #222;
    box-shadow: 0px 0px 4px #222;
    background-image: -moz-linear-gradient(top, #f8f8f8, #cccccc);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #f8f8f8), color-stop(1, #cccccc));
    background-image: -webkit-linear-gradient(top, #f8f8f8, #cccccc);
    background-image: -moz-linear-gradient(top, #f8f8f8, #cccccc);
    background-image: -ms-linear-gradient(top, #f8f8f8, #cccccc);
    background-image: -o-linear-gradient(top, #f8f8f8, #cccccc);
    opacity: 0;
    z-index: 99999;
    visibility: hidden;
}

[data-title] {
    position: relative;
    right: 40px;
}