.marque-head{
    margin-top:3%;
}
.marque-container {
  overflow-x: hidden;
  display: flex;
  align-items: center;
  flex-direction: row;
  position: relative;
  width: 70%;
  height: 25px;
  background-color: #3ad4af;
  margin:5rem auto 0;
}
.marquee {
  flex: 0 0 auto;
  min-width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: purple;
  margin: 0;
  animation: scroll 15s linear 0s infinite;
  -moz-animation: scroll 15s linear 0s infinite;
  -webkit-animation: scroll 15s linear 0s infinite;
}
.marque-edit{
    border:none;
    outline:none;
    padding:0.5rem 3rem;
    border-radius:3px;
    background:#3ad4af;
    color:white;
    align-items: center;
    font-size:larger;
    margin-top: 1rem;
    z-index:3000000000000
}
.edit-marque{
    display:flex;
    flex-direction: column;
    /* background:rgba(128, 128, 128, 0.233); */
    width:70%;
    margin:2% auto;
    box-shadow:1px 0px 5px 3px rgba(0,0,0,0.1)
}
.edit-marque input{
    width:80%;
    margin:2% auto 1%;
    padding:1%;
    border:none;
    outline:none;
    border-radius:3px;
    box-shadow:1px 1px 3px 1px rgba(0,0,0,0.1)
}
.edit-marque button{
    width:max-content;
    margin:2% auto;
}
@-moz-keyframes scroll {
  0% {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@-webkit-keyframes scroll {
  0% {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@keyframes scroll {
  0% {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
