.MuiTab-wrapper {
    color:#3ad4af !important;
    font-weight: bolder;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.PrivateTabIndicator-colorPrimary-3 {
    background-color: #3ad4af !important;
}
.makeStyles-root-1 {
    width:85% !important;
    margin:auto !important;
}
.ipo-img{
    height:4rem;
    object-fit: contain;
    width:15rem;
    /* background:black; */
}
.ipo-card{
    min-height:12rem;
    display:flex;
    justify-content: center;
    align-items: center;
    margin:2%;
    align-self: center;
    position:relative;
}
.ipo-card svg:first-child{
    background:rgb(72, 72, 233);
    position:absolute;
    top:0;
    right:8%;
    color:white;
    padding:1%;
    cursor:pointer;
}
.ipo-card svg:nth-child(2){
    background:rgb(247, 58, 58);
    position:absolute;
    top:0;
    right:0;
    color:white;
    padding:1%;
    cursor:pointer;
}
.ipo-card-title{
    background:#3ad4af;
    margin:5% 0% 2%;
    padding:1% 3%;
    border-radius:5px;
    color:white;
    min-width:80%;
}
.ipo-card-link{
    margin-bottom:0;
   border-bottom:2px solid transparent;
   cursor:pointer;
}
.ipo-card-link:hover{
   border-bottom:2px solid black;
}
.ipo-series-img{
    height:15rem;
}
.ppt-el{
    height:20rem;
    width:80%;
}
.form-radios{
    display:flex;
    width:80%;
    margin:2% auto;
    background:#b4b8bc5d;
    padding:2%;
}
.form-radios div{
    width:20%;
    margin:auto;
    display:flex;
    align-items: center;
    justify-content: space-around;
}
.form-radios div label{
    margin-right:auto;
    margin:auto -2%;
    min-width:70%;
}
.form-radios div input{
    margin:auto;
}
@media screen and (max-width:520px){
    .ipo-series-img{
        width:80vw;
    }
}
@media screen and (max-width:500px){
    .ipo-img{
        width:10rem;
    }
}