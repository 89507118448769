.newadd-cont{
    display:flex;
    flex-wrap:wrap;
    width:70%;
    margin:2rem auto;
    background: #FAFAFA;
    min-height:80vh;
    padding:2%;
    justify-content: center;
}
.newadd-box{
    background:#ffffff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left:2px solid #3ad4af;
    min-height:6rem;
    width:15rem;
    padding:1%;
    align-items: center;
    position:relative;
    height: max-content;
    margin:2%;
}
.newadd-box i{
    position:absolute;
    top:0.4rem;
    right:0.8rem;
    cursor:pointer;
}
.newadd-box h4{
    margin-top:0.75rem;
}
.newadd-box div{
    display:flex;
    align-items: center;
    justify-content: center;
}
.newadd-box div div{
    align-items: center;
    display:flex;
    justify-content: center;
    margin:3%;
}
.newadd-box div div label{
    margin:0;
    padding:0;
    margin-left:5%;
}